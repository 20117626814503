@font-face {
  font-family: 'Matter-Medium';
  src: local('Matter-Medium'), url('assets/fonts/Matter-Medium.woff2') format('woff2');
  font-weight: normal;
}
@font-face {
  font-family: 'Matter-Regular';
  src: local('Matter-Regular'), url('assets/fonts/Matter-Regular.woff2') format('woff2');
  font-weight: normal;
}
@font-face {
  font-family: 'Raw-Bold';
  src: local('Raw-Bold'), url('assets/fonts/RawBold.woff2') format('woff2');
  font-weight: normal;
}
